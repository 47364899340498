@media screen and (max-width: 1024px) {
    .card-container  {
        width: 100% !important;
    }

    .card {
        width: 90% !important;
    }
}

.card-container {
    margin: auto;
    width: 70%;
}

.card {
    margin: auto;
    width: 70%;
    padding-top: 20px;
}
