.bio-component-container {
    /* background: blue; */
    height: 100%;
    width: 70%;
    margin: auto;
    display: block;
    overflow-x: hidden;
    overflow: hidden;
    
    margin-top: 5vh;
}

.banner-title{
    margin-top: 5vh;
    /* background-color: blue; */
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    /* height: 50%; */
}

.text {
    margin: auto;
    font-size:  1rem;
    color: black;
    text-align: center;
    padding: 1vh;
    width: 70%;
}

#bio-component {
    /* display: grid; */
    height: 90%;
    width: 60%;
    grid-gap: 20px;
    margin: auto;
    margin-top: 5vh;
    grid-template-areas: 
    "pc1",
    "tb1";
}

@media screen and (max-width: 1024px) {
    #bio-component {
        /* margin-top: 10vh; */
        display: grid;
        height: 90%;
        width: 100%;
        grid-gap: 20px;
        margin: auto;
        margin-top: 5vh;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: minmax(1, auto);
        grid-template-areas: 
        "pc1"
        "tb1"
    }
}

@media screen and (max-width: 1024px) {

    #textbox1-component {
        height: 100%;
        width: 80%;
        border-radius: 30px;
        /* background-color: lightblue; */
        color: #2e2e2e;
        margin: auto;
        display: flex;
        flex-direction: column;
    }

    .bio-component-container {
        /* background: blue; */
        height: 100%;
        width: 100% !important;
        margin: auto;
        display: block;
        overflow-x: hidden;
        overflow: hidden;
        
        margin-top: 5vh;
    }
}

.picture-1 {
    grid-area: pc1;
    width: 100%;
    /* height: 100%; */
    display: flex;
    /* background-color: red; */
}

.picture-component-1 {
    /* height: 100%; */
    width: 100%;
    margin: auto;
    border-radius: 30px;
    /* background-color: lightblue; */
    color: #2e2e2e;
}

.textbox1-component {
    height: 100%;
    width: 100%;
    border-radius: 30px;
    /* background-color: lightblue; */
    color: #2e2e2e;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.textbox-1 {
    grid-area: tb1;
    width: 100%;
    /* height: 100%; */
    /* background-color: blue; */
}

.picture1-component {
    height: 100%;
    width: 100%;
    border-radius: 30px;
    /* background-color: lightblue; */
    color: #2e2e2e;
}

#primary-pic {
    height: auto;
    margin: auto;
    display: table-row;
    width: 35vh;
    border-radius: 35px;
}
